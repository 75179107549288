import React, { useEffect, useState } from "react";
import Sidebar from "../Components/Sidebar";
import Chart from "react-apexcharts";
import Header from "../Components/Header";
import axios from "axios";
import Helpers from "../../Config/Helpers";
import Loader from "../../layouts/Loader";

const Dashboard = () => {
  const [users, setUser] = useState('')
  const [newUser , setNewUser] = useState('')
  const [support , setSupport] = useState('')
  const [loader, setLoader] = useState(true)
  const [download , setDownload] = useState([])

  const getData = async () => {
    const response = await axios.get(`${Helpers.apiUrl}user/info`)
    setUser(response.data.user)
    setNewUser(response.data.new_users_today)
    setSupport(response.data.support)
  }
  useEffect(() => {
    getData()
    const timer = setTimeout(() => {
      setLoader(false)
    }, 1000)
    return () => setTimeout(timer)
  }, [])
  // const [areaChartOptions, setAreaChartOptions] = useState({
  //   chart: {
  //     type: 'area',
  //     height: 350,
  //     toolbar: {
  //       show: false,
  //     },
  //   },
  //   stroke: {
  //     curve: 'smooth',
  //   },
  //   colors: ['#FF7A50'],
  //   markers: {
  //     size: 5,
  //     colors: ['#FF7A50'],
  //   },
  //   xaxis: {
  //     categories: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
  //   },
  // });

  // const areaChartSeries = [
  //   {
  //     name: 'Site Traffic',
  //     data: [1500, 2300, 1800, 2500, 2200, 2900, 3200] // Example data for site traffic
  //   }
  // ];

  const [areaChartSeries, setAreaChartSeries] = useState([{ name: 'Site Traffic', data: [] }]);
  const [areaChartOptions, setAreaChartOptions] = useState({
    chart: {
      type: 'area',
      height: 350,
      toolbar: { show: false },
    },
    stroke: { curve: 'smooth' },
    colors: ['#FF7A50'],
    markers: { size: 5, colors: ['#FF7A50'] },
    xaxis: { categories: [] },
  });

  useEffect(() => {
    const fetchTrafficData = async () => {
      try {
        const response = await axios.get(`${Helpers.apiUrl}traffic`);
        console.log('API Response:', response.data);
        const data = response.data;
  
        if (data) {
          // Sort data by date in ascending order
          const sortedData = data.sort((a, b) => {
            const dateA = new Date(`${a.date.slice(0, 4)}-${a.date.slice(4, 6)}-${a.date.slice(6)}`);
            const dateB = new Date(`${b.date.slice(0, 4)}-${b.date.slice(4, 6)}-${b.date.slice(6)}`);
            return dateA - dateB; // Ascending order
          });
        
          console.log("Sorted Data:", sortedData); // Add this to debug
        
          // Extract dates and totalUsers
          const dates = sortedData.map(item => {
            const dateStr = item.date;
            const formattedDate = new Date(
              `${dateStr.slice(0, 4)}-${dateStr.slice(4, 6)}-${dateStr.slice(6)}`
            ).toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
            return formattedDate;
          });
        
          const totalUsers = sortedData.map(item => parseInt(item.totalUsers, 10));
        
          console.log("Formatted Dates:", dates); // Add this to check dates
          console.log("Total Users:", totalUsers); // Debug total users
        
          // Update chart options and series
          setAreaChartOptions(prevOptions => ({
            ...prevOptions,
            xaxis: { categories: dates },
          }));
        
          setAreaChartSeries([{ name: 'Site Traffic', data: totalUsers }]);
        }
        
      } catch (error) {
        console.error("Error fetching traffic data:", error);
      }
    };
  
    fetchTrafficData();
  }, []);
  

  const [pieChartSeries, setPieChartSeries] = useState([0, 0]);
  const [pieChartOptions, setPieChartOptions] = useState({
    chart: {
      type: 'pie',
    },
    labels: ['Active Users', 'Inactive Users'],
    colors: ['#FF7A50', '#FFAA33'],
    legend: {
      position: 'bottom',
    },
  });

  const fetchUserStats = async () => {
    try {
      const response = await axios.get(`${Helpers.apiUrl}userstats`);
      const data = response.data;

      if (data) {
        const { activeUsers, inactiveUsers } = data;
        setPieChartSeries([parseInt(activeUsers, 10), parseInt(inactiveUsers, 10)]);
      }
    } catch (error) {
      console.error("Error fetching user stats:", error);
    }
  };

  const fetchDownloadStat = async () => {
    try {
      const response = await axios.get(`${Helpers.apiUrl}downloadstats`);
      setDownload(response.data.data)
    } catch (error) {
      console.error("Error fetching user stats:", error);
    }
  };
  useEffect(() => {
    fetchDownloadStat()
    fetchUserStats();
  }, []);

  return (
    <>
      {loader ? <Loader /> :
        <>
          <Header />
          <div id="kt_app_wrapper" className="app-wrapper flex-column flex-row-fluid">
            <Sidebar />
            <div className="container mx-auto p-6">
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4 gap-6">
                <div className="bg-white rounded-lg shadow-sm p-4 flex items-center">
                  <div className="w-16 h-16 rounded-full overflow-hidden bg-gray-300 flex items-center justify-center">
                    <i className="fa-regular fa-user text-white" style={{ fontSize: "1.5rem" }}></i>
                  </div>
                  <div className="ml-4">
                    <h3 className="text-xl font-semibold">Registered User</h3>
                    <p className="text-gray-600">{users}</p>
                  </div>
                </div>

                <div className="bg-white rounded-lg shadow-sm p-4 flex items-center">
                  <div className="w-16 h-16 rounded-full overflow-hidden bg-gray-300 flex items-center justify-center">
                    <i class="fa-regular fa-user-plus" style={{ fontSize: "1.5rem" }}></i>
                  </div>
                  <div className="ml-4">
                    <h3 className="text-xl font-semibold">New Users Today</h3>
                    <p className="text-gray-600">{newUser}</p>
                  </div>
                </div>

                <div className="bg-white rounded-lg shadow-sm p-4 flex items-center">
                  <div className="w-16 h-16 rounded-full overflow-hidden bg-gray-300 flex items-center justify-center">
                    <i className="fa-solid fa-download" style={{ fontSize: "1.5rem" }}></i>
                  </div>
                  <div className="ml-4">
                    <h3 className="text-xl font-semibold">Total Downloads</h3>
                    <p className="text-gray-600">{download}</p>
                  </div>
                </div>

                <div className="bg-white rounded-lg shadow-sm p-6 flex items-center">
                  <div className="w-16 h-16 rounded-full overflow-hidden bg-gray-300 flex items-center justify-center">
                    <i className="fa-regular fa-message-smile" style={{ fontSize: "1.5rem" }}></i>
                  </div>
                  <div className="ml-4">
                    <h3 className="text-xl font-semibold">Support Request</h3>
                    <p className="text-gray-600">{support}</p>
                  </div>
                </div>

              </div>
            </div>
            <div className="container mx-auto mt-3 px-5">
              <div className="grid grid-cols-1 lg:grid-cols-12 gap-4">
                <div className="card col-span-1 lg:col-span-8 mb-4 shadow-sm"> {/* 8/12 width */}
                  <h4 className="text-dark mb-3 font-bold ml-4 mt-3 pt-3">Live Site Traffic</h4>
                  <Chart
                    options={areaChartOptions}
                    series={areaChartSeries}
                    type="area"
                    height={300}
                    className="px-5"
                  />
                </div>
                <div className="card col-span-1 lg:col-span-4 mb-4 shadow-sm"> {/* 4/12 width */}
                  <h4 className="text-dark mb-3 font-bold ml-4 mt-3 pt-3">User Activity</h4>
                  <div className="block w-full">
                    <Chart
                      options={pieChartOptions}
                      series={pieChartSeries}
                      type="pie"
                      height={300}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      }
    </>
  );
}

export default Dashboard;
