import React from "react";
import HelmetWrapper from "../../Config/HelmetWrapper";
// import Helpers from '../../Config/Helpers';
// import axios from "axios";
function Header() {
  // const [notifications, setNotifications] = useState([]);
  // const [unreadCount, setUnreadCount] = useState(0);
  // const [isDropdownVisible, setIsDropdownVisible] = useState(false);

  // // Toggle dropdown visibility on bell click
  // const toggleDropdown = () => {
  //   setIsDropdownVisible(!isDropdownVisible);
  // };
  // useEffect(() => {
  //   // Fetch unread notifications from the backend
  //   axios.get(`${Helpers.apiUrl}admin/notifications`, Helpers.authHeaders)
  //     .then((response) => {
  //       console.log('res', response)
  //       setNotifications(response.data);
  //       setUnreadCount(response.data.filter(n => !n.read_at).length);
  //     })
  //     .catch((error) => {
  //       console.error('Error fetching notifications:', error);
  //     });
  // }, []);

  // const markAsRead = (id) => {
  //   console.log('token', Helpers.authHeaders);  // Confirm headers are correct
  //   axios.post(`${Helpers.apiUrl}admin/notifications/${id}/read`, {}, Helpers.authHeaders)
  //     .then(() => {
  //       setNotifications(notifications.map(notification =>
  //         notification.id === id ? { ...notification, read_at: new Date() } : notification
  //       ));
  //       setUnreadCount(unreadCount - 1);
  //     })
  //     .catch(error => {
  //       console.error('Error marking as read:', error);
  //     });
  // };

  return (
    <>
      <HelmetWrapper />
      <div
        id="kt_app_header"
        className="app-header "
        data-kt-sticky="true"
        data-kt-sticky-activate="{default: true, lg: true}"
        data-kt-sticky-name="app-header-minimize"
        data-kt-sticky-offset="{default: '200px', lg: '0'}"
        data-kt-sticky-animation="true"
      >
        {/* <div
          className="app-container  container-fluid d-flex align-items-stretch justify-content-between "
          id="kt_app_header_container"
        >



          <div
            className="d-flex align-items-stretch justify-content-between flex-lg-grow-1"
            id="kt_app_header_wrapper"
          >
            <div
              className="app-header-menu app-header-mobile-drawer align-items-stretch"
              data-kt-drawer="true"
              data-kt-drawer-name="app-header-menu"
              data-kt-drawer-activate="{default: true, lg: false}"
              data-kt-drawer-overlay="true"
              data-kt-drawer-width="250px"
              data-kt-drawer-direction="end"
              data-kt-drawer-toggle="#kt_app_header_menu_toggle"
              data-kt-swapper="true"
              data-kt-swapper-mode="{default: 'append', lg: 'prepend'}"
              data-kt-swapper-parent="{default: '#kt_app_body', lg: '#kt_app_header_wrapper'}"
            >
              <div
                className="
                menu 
                menu-rounded  
                menu-column 
                menu-lg-row 
                my-5 
                my-lg-0 
                align-items-stretch 
                fw-semibold
                px-2 px-lg-0
                  "
                id="kt_app_header_menu"
                data-kt-menu="true"
              >
                <div
                  data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
                  data-kt-menu-placement="bottom-start"
                  className="menu-item here show menu-here-bg menu-lg-down-accordion me-0 me-lg-2"
                >
                  <a href="#" className="mt-4 md:mt-8 lg:mt-12 bg-[#FF7A50] hover:bg-orange-700  font-bold py-2 px-4 rounded-xl duration-300" style={{ color: "white" }}>Dashboard</a>
                </div>

              </div>
            </div>
          </div>
          <div className="notification-bell">
            <i className="fa-regular fa-bell cursor-pointer" style={{ fontSize: '1.3rem' }}></i>
            {unreadCount > 0 && (
              <span className="notification-badge">{unreadCount}</span>
            )}
            {notifications.length > 0 && (
              <div className="notification-dropdown">
                {notifications.map(notification => (
                  <div key={notification.id} className="notification-item">
                    <div>{notification.data.title}</div>
                    <div>{notification.data.message}</div>
                    <button onClick={() => markAsRead(notification.id)}>Mark as Read</button>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div> */}
        <div className="container mx-auto">
          <div className="flex justify-between items-center mt-10 px-3">
            <div >
              <a href="#" className="mt-4 md:mt-8 lg:mt-12 bg-[#FF7A50] hover:bg-orange-700  font-bold py-2 px-4 rounded-xl duration-300" style={{ color: "white" }}>Dashboard</a>
            </div>
            {/* <div className="flex items-center space-x-4 relative">
              <div className="relative">
                <i
                  className="fa-regular fa-bell cursor-pointer text-xl"
                  onClick={toggleDropdown}
                ></i>
                {unreadCount > 0 && (
                  <span className="absolute -top-1 -right-1 bg-red-500 text-white rounded-full px-2 text-xs">
                    {unreadCount}
                  </span>
                )}
              </div>

              {isDropdownVisible && notifications.length > 0 && (
                <div className="absolute right-0 mt-2 w-64 bg-white border border-gray-200 shadow-lg rounded-lg z-10">
                  {notifications.map(notification => (
                    <div key={notification.id} className="p-4 border-b last:border-b-0">
                      <div className="font-semibold text-sm">{notification.data.title}</div>
                      <div className="text-sm text-gray-500">{notification.data.message}</div>
                      <button
                        onClick={() => markAsRead(notification.id)}
                        className="mt-2 text-blue-500 text-xs">
                        Mark as Read
                      </button>
                    </div>
                  ))}
                </div>
              )}

              <img src="/assets/user.png" alt="User" className="ml-8 w-8 h-8 rounded-full" />
            </div> */}
          </div>
        </div>
      </div>

    </>
  );
}

export default Header;
