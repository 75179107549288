import axios from 'axios'
import React, { useEffect, useState } from 'react'
import Helpers from '../../../Config/Helpers'
import Sidebar from '../../Components/Sidebar'
import Loader from '../../../layouts/Loader'

function SupportSection() {
    const [questions, setQuestion] = useState([])
    const [loader , setLoader ] = useState(false)
    const [currentPage, setCurrentPage] = useState(0);
    const [paginatedData, setPaginatedData] = useState([]);
    const getQuestion = async () => {
        setLoader(true)
        const response = await axios.get(`${Helpers.apiUrl}question/show`)
        const pages = response.data.data
        setQuestion(response.data.data)
        setPaginatedData(Helpers.paginate(pages));
        setLoader(false)
    }
    const read = async () => {
        setLoader(true)
        const response = await axios.get(`${Helpers.apiUrl}question/read`)
        setLoader(false)
    }

    const handlePageChange = (pageIndex) => {
        setCurrentPage(pageIndex);
    };
    useEffect(() => {
        getQuestion()
        read()
    }, [])
    return (
        <div>
            <div id="kt_app_wrapper" className="app-wrapper flex-column flex-row-fluid">
                <Sidebar />
                {loader ? <Loader />
                :
                <div className="card mb-5 mb-xl-8 bg-slate-200" style={{ marginTop: "-4%" }}>
                    <div className="card-header border-0 pt-5">
                        <h3 className="card-title align-items-start flex-column">
                            <span className="card-label fw-bold fs-3 mb-1">Questions</span>
                        </h3>
                    </div>
                    <div className="card-body py-3 rounded m-5 bg-gray-100">
                        <div className="table-responsive">
                            <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                                <thead>
                                    <tr className="fw-bold text-muted">
                                        <th className="min-w-10px">#</th>
                                        <th className="min-w-50px">Image</th>
                                        <th className="min-w-150px">Subject</th>
                                        <th className="min-w-150px">Email</th>
                                        <th className="min-w-250px">Help</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {paginatedData[currentPage]?.map((question, index) => (
                                        <tr key={question.id}>
                                            <td>{index + 1}</td>
                                            <td>
                                                <div className="d-flex align-items-center">
                                                    <div className="symbol symbol-45px me-5 bg-pinkbackground p-2">
                                                        <img src={`${Helpers.basePath}/storage/${question.image}`} alt="" />
                                                    </div>
                                                </div>
                                            </td>
                                            <td>{question.subject}{question.is_read === 0 ? <span class="bg-blue-100 text-blue-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300">New</span> : ''}</td>
                                            <td>{question.email}</td>
                                            <td>{question.help}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className='float-end'>
                            {paginatedData.map((_, pageIndex) => (
                                <button
                                    key={pageIndex}
                                    onClick={() => handlePageChange(pageIndex)}
                                    style={{
                                        margin: '0 5px',
                                        padding: '5px',
                                        background: currentPage === pageIndex ? '#FF7A50' : 'gray',
                                        color: 'white'
                                    }}
                                    className='mt-4 md:mt-8 lg:mt-12 bg-[#FF7A50] hover:bg-orange-700 font-bold py-2 px-4 rounded-xl transition duration-300'
                                >
                                    {pageIndex + 1}
                                </button>
                            ))}
                        </div>
                    </div>
                </div>
                }
            </div>
        </div>
    )
}

export default SupportSection