// import JustHeader from './components/download/JustHeader';


function Layout({ children }) {
  return (
    <div>
      {/* <Header /> */}
      {children}
      {/* <Footer /> */}
    </div>
  );
}

export default Layout;
