import Helpers from "../Config/Helpers";

export const isAuthenticated = () => {
    const loginTime = Helpers.getItem("loginTimestamp");
    const currentTime = new Date().getTime();
    const minutesPassed = Math.floor((currentTime - loginTime) / (1000 * 60));
  
    if (loginTime && minutesPassed >= 120) {
      localStorage.clear(); 
      Helpers.toast("error", "Session expired. Login again to continue");
      return false; 
    }
    return !!localStorage.getItem("token"); 
  };